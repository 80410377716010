import type { SearchResponse, RequestOptionsObject } from '@nuxtjs/algolia';
import type { RecommendationsQuery } from '@algolia/recommend';
import type { RecommendClient } from '@algolia/recommend/dist/recommend';

type RecommendParams = {
    queries: RecommendationsQuery[];
} & RequestOptionsObject;

type MultipleQueriesResponse<T> = {
    results: Array<SearchResponse<T>>;
};

/**
 * Copy of the original useAlgoliaRecommend from @nuxtjs/algolia
 * to avoid the usage of useState, because we only need the response of get() method
 */
export const useCustomAlgoliaRecommend = () => {
    const { $algoliaRecommend } = useNuxtApp();
    const algoliaRecommend = $algoliaRecommend as RecommendClient;
    if (!algoliaRecommend) {
        throw new Error(
            '`[@nuxtjs/algolia]` Cannot call useAlgoliaRecommend composable due to missing `algolia.recommend` option.',
        );
    }

    const get = async <T>({ queries, requestOptions }: RecommendParams): Promise<MultipleQueriesResponse<T>> =>
        algoliaRecommend.getRecommendations(queries, requestOptions) as unknown as MultipleQueriesResponse<T>;

    return { get };
};
