import type { AlgoliaProduct } from '~/types/models/algoliaProduct';
import { Sorter } from '~/helpers/Sorter';
import { getTranslatedProperty } from '@shopware-pwa/helpers-next';
import type { AhProduct } from '~/types/models/product/product';
import { ArrayHelper } from '~/helpers/ArrayHelper';
import type { RecommendationsQuery } from '@algolia/recommend';
import { ObjectHelper } from '~/helpers/ObjectHelper';
import type { TrendingItemsQuery } from '@algolia/recommend/dist/recommend';

export const useAlgoliaProductSlider = () => {
    // general composable usages
    const { lastSeen } = useLastSeen('product');
    const { searchWishlists, wishlistWithDefaultFlag } = useWishlists();
    const { accessories } = useCrossSellings();
    const { page, product } = usePage();
    const manufacturerName = computed(() => getTranslatedProperty(product.value?.manufacturer, 'name'));
    const seriesName = computed(() =>
        getTranslatedProperty((product.value as AhProduct)?.extensions?.attributes?.productSeriesEntity, 'name'),
    );
    const { isLoggedIn } = useUser();

    // products and product numbers for each tab
    const { data: lastSeenProducts } = useNuxtData<AlgoliaProduct[] | null>('slider-last-seen-products');
    const lastSeenProductNumbers = computed(() => lastSeen.value);

    const { data: wishlistProducts } = useNuxtData<AlgoliaProduct[] | null>('slider-wishlist-products');
    const wishlistProductNumbers = computed(() =>
        (wishlistWithDefaultFlag.value?.productCollection ?? [])
            .map((item) => item?.product?.productNumber || '')
            .filter((productNumber) => productNumber !== ''),
    );

    const { data: seriesProducts } = useNuxtData<AlgoliaProduct[] | null>('slider-series-products');
    const seriesProductNumbers = computed(() => seriesProducts.value?.map((product) => product.productNumber) ?? []);

    const { data: accessoriesProducts } = useNuxtData<AlgoliaProduct[] | null>('slider-accessories-products');
    const accessoriesProductNumbers = computed(() => (accessories.value ?? []).map((product) => product.productNumber));

    const { data: customerBoughtProducts } = useNuxtData<AlgoliaProduct[] | null>('slider-customer-bought-products');
    const customerBoughtProductNumbers = computed(
        () => customerBoughtProducts.value?.map((product) => product.productNumber) ?? [],
    );

    const { data: noveltyRecoProducts } = useNuxtData<AlgoliaProduct[] | null>('slider-novelty-reco-products');
    const noveltyRecoProductNumbers = computed(
        () => noveltyRecoProducts.value?.map((product) => product.productNumber) ?? [],
    );

    const { data: recoProducts } = useNuxtData<AlgoliaProduct[] | null>('slider-reco-products');
    const recoProductNumbers = computed(() => recoProducts.value?.map((product) => product.productNumber) ?? []);

    const { data: noveltyProducts } = useNuxtData<AlgoliaProduct[] | null>('slider-novelty-products');
    const noveltyProductNumbers = computed(() => noveltyProducts.value?.map((product) => product.productNumber) ?? []);

    const { data: bestsellerProducts } = useNuxtData<AlgoliaProduct[] | null>('slider-bestseller-products');
    const bestsellerProductNumbers = computed(
        () => bestsellerProducts.value?.map((product) => product.productNumber) ?? [],
    );

    const allProductNumbers = computed(() =>
        ArrayHelper.merge(
            lastSeenProductNumbers.value,
            wishlistProductNumbers.value,
            seriesProductNumbers.value,
            accessoriesProductNumbers.value,
            customerBoughtProductNumbers.value,
            noveltyRecoProductNumbers.value,
            noveltyProductNumbers.value,
            recoProductNumbers.value,
            bestsellerProductNumbers.value,
        ),
    );

    const { productIndex, getDefaultFacetFilters } = useAlgolia();
    const { search } = useAlgoliaSearch(productIndex.value);
    const defaultFacetFilters = getDefaultFacetFilters();
    const { get } = useCustomAlgoliaRecommend();

    const _createRecoQuery = (
        modelName: string,
        config: Partial<RecommendationsQuery | TrendingItemsQuery> = {},
        limit: number = 15,
    ): RecommendationsQuery => {
        const defaultQueryConfig: RecommendationsQuery = {
            maxRecommendations: +limit,
            indexName: productIndex.value,
            // @ts-ignore e.g. trending-items model exists in algolia, but is not typed
            model: modelName,
            queryParameters: {
                // @ts-ignore
                facetFilters: [...defaultFacetFilters],
                clickAnalytics: true,
            },
        };

        return ObjectHelper.merge(defaultQueryConfig, config) as RecommendationsQuery;
    };

    // helper function to get products from Algolia based on facets
    const _doAlgoliaSearch = async (facets: string[] | string[][]) => {
        if (!facets.length) return;

        try {
            const response = await search({
                query: '',
                requestOptions: {
                    // @ts-ignore
                    facetFilters: [...defaultFacetFilters, ...facets],
                    hitsPerPage: 15,
                    clickAnalytics: true,
                },
            });

            return response?.hits as AlgoliaProduct[];
        } catch (e) {
            Logger.captureException(e);
        }
    };

    const _doAlgoliaRecoSearch = async (
        queries: Array<RecommendationsQuery>,
        limit: number | undefined = undefined,
    ) => {
        // Algolia Reco is not working on SRR, so early return
        if (!queries.length || import.meta.server) return;

        try {
            const uniqueHits = new Map();
            // algolia only allows to send 50 queries at once
            const response = await get<AlgoliaProduct & { _score: number }>({ queries: queries.slice(0, 50) });

            const allSortedHits = toRaw(response)
                .results.flatMap((result) => result.hits ?? [])
                .sort((a, b) => a._score - b._score)
                .reverse()
                .filter((hit) => {
                    // filter out duplicates
                    if (uniqueHits.has(hit.objectID)) {
                        return false;
                    }

                    uniqueHits.set(hit.objectID, true);
                    return true;
                });

            return limit ? allSortedHits.slice(0, +limit) : allSortedHits;
        } catch (e) {
            Logger.captureException(e);
        }
    };

    const getLastSeenProducts = async (): Promise<AlgoliaProduct[] | null> => {
        const facets = lastSeenProductNumbers.value.map((productNumber) => `objectID:${productNumber}`);

        const hits = await _doAlgoliaSearch([facets]);
        if (!hits || hits.length === 0) return null;

        // reorder hits based on lastSeen history
        return Sorter.sortByDefinedOrder(hits, lastSeen.value, 'objectID') || null;
    };

    const getWishlistProducts = async (): Promise<AlgoliaProduct[] | null> => {
        if (!isLoggedIn.value) return null;

        await searchWishlists();
        const facets = wishlistProductNumbers.value.map((productNumber) => `objectID:${productNumber}`);
        const hits = await _doAlgoliaSearch([facets]);

        return hits?.length ? hits : null;
    };

    const getSeriesProducts = async (): Promise<AlgoliaProduct[] | null> => {
        if (!seriesName.value) return null;

        const facets = [`serie:${seriesName.value}`];
        const hits = await _doAlgoliaSearch(facets);

        return hits?.length ? hits : null;
    };

    const getAccessoriesProducts = async (): Promise<AlgoliaProduct[] | null> => {
        if (!accessories.value?.length) return null;

        const facets = accessoriesProductNumbers.value.map((productNumber) => `objectID:${productNumber}`);
        const hits = await _doAlgoliaSearch([facets]);

        return hits?.length ? hits : null;
    };

    const getNoveltyProducts = async (): Promise<AlgoliaProduct[] | null> => {
        const facets = ['isNovelty:true'];

        if (manufacturerName.value) {
            facets.push(`manufacturer:${manufacturerName.value}`);
        }

        const hits = await _doAlgoliaSearch(facets);

        return hits?.length ? hits : null;
    };

    const getCustomerBoughtProducts = async (): Promise<AlgoliaProduct[] | null> => {
        const modelName = 'bought-together';

        if (!product.value?.productNumber) return null;

        const queries = [
            _createRecoQuery(modelName, {
                objectID: product.value.productNumber,
            }),
        ];

        const hits = await _doAlgoliaRecoSearch(queries);

        return hits?.length ? hits : null;
    };

    const getNoveltyRecoProducts = async (): Promise<AlgoliaProduct[] | null> => {
        const modelName = 'trending-items';
        const noveltyQuery: Partial<RecommendationsQuery | TrendingItemsQuery> = {
            facetName: 'isNovelty',
            facetValue: 'true',
        };

        if (manufacturerName.value) {
            // @ts-ignore read-only property
            noveltyQuery.queryParameters = {
                filters: `"manufacturer":"${escapeAlgoliaValue(manufacturerName.value)}"`,
            };
        }

        const queries = [_createRecoQuery(modelName, noveltyQuery)];
        const hits = await _doAlgoliaRecoSearch(queries);

        return hits?.length ? hits : null;
    };

    const getRecoProducts = async (): Promise<AlgoliaProduct[] | null> => {
        if (!product.value?.productNumber) return null;

        const modelName = 'related-products';
        const queries = [
            _createRecoQuery(modelName, {
                objectID: product.value.productNumber,
            }),
        ];
        const hits = await _doAlgoliaRecoSearch(queries);

        return hits?.length ? hits : null;
    };

    const getBestsellerProducts = async (): Promise<AlgoliaProduct[] | null> => {
        const modelName = 'trending-items';
        const queries = [];

        // add manufacturer filter if product is available
        if (manufacturerName.value) {
            queries.push(
                _createRecoQuery(modelName, {
                    facetName: 'manufacturer',
                    facetValue: manufacturerName.value,
                }),
            );
        }

        // add category filter if category page is available
        if (page.value?.category?.id) {
            queries.push(
                _createRecoQuery(modelName, {
                    facetName: 'categoryTreeIds',
                    facetValue: page.value?.category?.id,
                }),
            );
        }

        const hits = await _doAlgoliaRecoSearch(queries, 15);

        return hits?.length ? hits : null;
    };

    return {
        getLastSeenProducts,
        getWishlistProducts,
        getSeriesProducts,
        getAccessoriesProducts,
        getCustomerBoughtProducts,
        getNoveltyRecoProducts,
        getRecoProducts,
        getNoveltyProducts,
        getBestsellerProducts,
        wishlistProducts,
        lastSeenProducts,
        seriesProducts,
        accessoriesProducts,
        customerBoughtProducts,
        noveltyRecoProducts,
        noveltyProducts,
        bestsellerProducts,
        allProductNumbers,
        accessoriesProductNumbers,
        lastSeenProductNumbers,
        customerBoughtProductNumbers,
        noveltyRecoProductNumbers,
        bestsellerProductNumbers,
    };
};
